import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AnimotoBlogLayout from '../../components/AnimotoBlogLayout';
import SearchResults from '../../templates/SearchResults';

export default function Search({ location }) {
  const SEOInfo = {
    title         : 'Search Results',
    canonicalSlug : '/blog'
  };
  return (
    <AnimotoBlogLayout SEOInfo={SEOInfo}>
      <StaticQuery
        query={graphql`
            query SearchResultsQuery {
                siteSearchIndex {
                    index
                }
                contentfulBlogSettings {
                  featuredPosts {
                    id
                    title
                    updatedAt
                    publishedOn
                    featuredImage {
                      fluid(maxWidth: 670, maxHeight: 447) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                    }
                    mainCategory {
                      name
                      fields {
                        categorySlug
                      }
                    }
                    fields {
                      postSlug
                    }
                  }
                }
            }
        `}
        render={({ siteSearchIndex, contentfulBlogSettings }) => (
          <SearchResults
            featuredArticles={contentfulBlogSettings.featuredPosts}
            locationSearch={location.search}
            searchIndex={siteSearchIndex.index}
          />
        )}
      />
    </AnimotoBlogLayout>
  );
}

Search.propTypes = {
  location : PropTypes.shape({
    search : PropTypes.string.isRequired
  }).isRequired
};
