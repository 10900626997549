// extracted by mini-css-extract-plugin
export var base = "SearchResults-module--base--2BkMV";
export var rightAlignedContainer = "SearchResults-module--rightAlignedContainer--1Pvs-";
export var rightAlignedContainerSmall = "SearchResults-module--rightAlignedContainerSmall--2e3iV";
export var leftAlignedContainer = "SearchResults-module--leftAlignedContainer--1eK5L";
export var blogContainer = "SearchResults-module--blogContainer--NEcks SearchResults-module--base--2BkMV";
export var container = "SearchResults-module--container--19sju SearchResults-module--base--2BkMV";
export var largeContainer = "SearchResults-module--largeContainer--1Kuo- SearchResults-module--base--2BkMV";
export var mobileSmallPadding = "SearchResults-module--mobileSmallPadding--2vSRe";
export var fullWidthMobile = "SearchResults-module--fullWidthMobile--1DKCY";
export var gridWithSidebar = "SearchResults-module--gridWithSidebar--qP9CC";
export var noMaxWidth = "SearchResults-module--noMaxWidth--3eWpQ";
export var loadWrap = "SearchResults-module--loadWrap--1GGKd";
export var wrap = "SearchResults-module--wrap--2uagi SearchResults-module--blogContainer--NEcks SearchResults-module--base--2BkMV SearchResults-module--gridWithSidebar--qP9CC";
export var header = "SearchResults-module--header--yTptI";
export var headerCopy = "SearchResults-module--headerCopy--2q0tc";
export var searchResults = "SearchResults-module--searchResults--3nABS";
export var sidebar = "SearchResults-module--sidebar--2GC20";