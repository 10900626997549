import {
  ArticlePreview,
  BlogSearchResultsHeader,
  InfiniteArticlesList,
  LoadingSpinnerIcon,
  Paragraph,
  SectionTitle
} from '@animoto/components';
import { Index } from 'elasticlunr';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';

import RecentPostsSidebar from '../components/RecentPostsSidebar';
import searchBlog from '../utils/searchBlog';
import {
  header,
  headerCopy,
  loadWrap,
  searchResults,
  sidebar,
  wrap
} from './SearchResults.module.css';

function getSearchQueryFromUrl(locationSearch) {
  const regex = new RegExp('[\\?&]query=([^&#]*)');
  const results = regex.exec(locationSearch);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Displays blog search results using elasticlunr
 *
 * @returns {Component} SearchResults componenet
 */
export default class SearchResults extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    const newQuery = getSearchQueryFromUrl(props.locationSearch);

    const {
      isClient,
      query,
      searchIndex
    } = state;

    if (newQuery !== query) {
      return {
        isClient,
        query     : newQuery,
        results   : searchBlog(searchIndex, newQuery).filter((post) => !post.noIndex && !post.noIndexOnBlog),
        showIndex : 6,
        searchIndex
      };
    }

    return state;
  }

  /**
   * Sets initial state and sets up elasticlunr
   *
   * @method constructor
   *
   * @param  {Object} props Props passed to component
   *
   */
  constructor(props) {
    super(props);

    const searchIndex = this.createIndex();
    const query = getSearchQueryFromUrl(props.locationSearch);

    this.state = {
      isClient  : false,
      query,
      results   : searchBlog(searchIndex, query).filter((post) => !post.noIndex && !post.noIndexOnBlog),
      showIndex : 6,
      searchIndex
    };

    this.onShowMoreResults = this.onShowMoreResults.bind(this);
  }

  componentDidMount() {
    this.setState({
      isClient : true
    });
  }

  /**
   * Event handler for showing more search results
   *
   * @method onShowMoreResults
   *
   */
  onShowMoreResults() {
    this.setState((prevState) => ({
      showIndex : prevState.showIndex + 3
    }));
  }

  /**
   * Loads elasticlunr index so that search can
   * function
   *
   * @method createIndex
   *
   * @returns {Object} elasticlunr search index
   */
  createIndex() {
    const { searchIndex } = this.props;
    return Index.load(searchIndex);
  }

  render() {
    const { query, results, showIndex, isClient } = this.state;

    if (!isClient) {
      return (
        <div className={loadWrap}>
          <LoadingSpinnerIcon size="large" />
        </div>
      );
    }

    const { featuredArticles } = this.props;

    let articles;
    if (results.length) {
      articles = results.slice(0, showIndex);
    } else {
      articles = featuredArticles;
    }

    return (
      <div className={wrap}>
        {results && results.length ? (
          <BlogSearchResultsHeader
            className={header}
            query={query}
          />
        ) : (
          <div className={header}>
            <SectionTitle className={headerCopy}>There are no results for this search term</SectionTitle>
            <Paragraph className={headerCopy}>Please check the spelling of your search term, or try a different word or phrase.</Paragraph>
            <Paragraph className={headerCopy}>For general information about video marketing or video ideas, check out the featured posts below.</Paragraph>
          </div>
        )}
        <InfiniteArticlesList
          articleAlignLeft
          className={searchResults}
          hideShowMore={results.length <= showIndex}
          onShowMoreClick={this.onShowMoreResults}
        >
          {articles.map(({
            id,
            title,
            featuredImage,
            retinaFeaturedImage,
            fields,
            updatedAt,
            publishedOn,
            mainCategory
          }) => (
            <ArticlePreview
              key={id}
              alignLeft
              category={(mainCategory && mainCategory.name) || ''}
              categoryUrl={(mainCategory && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
              imageSrc={featuredImage && featuredImage.fixed && featuredImage.fixed.src}
              imageSrcFluid={featuredImage && featuredImage.fluid}
              imageSrcRetina={retinaFeaturedImage && retinaFeaturedImage.fixed && retinaFeaturedImage.fixed.src}
              postUrl={`/blog/${fields.postSlug}`}
              publishDate={publishedOn || updatedAt}
              title={title}
            />
          ))}
        </InfiniteArticlesList>
        <MediaQuery minWidth={768}>
          <RecentPostsSidebar className={sidebar} />
        </MediaQuery>
      </div>
    );
  }
}

SearchResults.propTypes = {
  featuredArticles : PropTypes.arrayOf(PropTypes.shape({
    featuredImage : PropTypes.shape({
      fixed : PropTypes.shape({
        src : PropTypes.string
      })
    }),
    fields : PropTypes.shape({
      postSlug : PropTypes.string
    }),
    id           : PropTypes.string,
    mainCategory : PropTypes.shape({
      fields : PropTypes.shape({
        categorySlug : PropTypes.string
      }),
      name : PropTypes.string
    }),
    publishedOn         : PropTypes.string,
    retinaFeaturedImage : PropTypes.shape({
      fixed : PropTypes.shape({
        src : PropTypes.string
      })
    }),
    title     : PropTypes.string,
    updatedAt : PropTypes.string
  })).isRequired,
  locationSearch : PropTypes.string.isRequired,
  searchIndex    : PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};
