import sortBlogPostsResultsByDate from './sortBlogPostsResultsByDate';

/**
   * Searches elasticlunr index JSON blob with search term
   *
   * @method searchBlog
   *
   * @param {Object} searchIndex Elasticlunr search index blob to use for searching
   * @param {String} query String to search for in blog posts
   *
   * @returns {Array} Search results ready to render
   */
export default function searchBlog(searchIndex, query) {
  return searchIndex.search(query, { expand : true })
    .map(({ ref }) => {
      const { featuredImage, mainCategory, ...rest } = searchIndex.documentStore.getDoc(ref);
      let result = {
        ...rest
      };

      if (featuredImage) {
        const { file } = featuredImage;
        const { url } = file;
        result = {
          ...result,
          featuredImage : {
            fixed : {
              src : `${url}?w=300&h=200&q=50&fit=fill`
            }
          },
          retinaFeaturedImage : {
            fixed : {
              src : `${url}?w=600&h=400&q=50&fit=fill`
            }
          }
        };
      }

      if (mainCategory) {
        const { name, fields } = mainCategory;
        result = {
          ...result,
          mainCategory : {
            name,
            fields : {
              categorySlug : fields.categorySlug
            }
          }
        };
      }
      return result;
    }).sort(sortBlogPostsResultsByDate);
}
